import { createContext, useContext, useEffect, useMemo, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  BRANDING_DATA_KEY,
  DEEP_LINK,
  NAV_HISTORY_KEY,
  OccupancyStatusCodes,
  TENANCY_ID_KEY,
  USER_TENANCIES,
  IS_FIRST_TIME_LOGIN,
  HIDE_OFFER_BANNER,
  BANNER_OFFER_DURATION
} from "@src/types";

export type GlobalContextType = {
  tenancyId?: number;
  redirectId?: number;
  getSelectedTenancy: TenanacyData | undefined;
  hasMultipleTenancies: boolean;
  handleSetTenancyId: (id: number) => void;
  handleSetTenancyData: (tenancyData: TenanacyData[]) => void;
  handleSetRedirectId: (id: number) => void; // TODO: remove redirect id logic into global state, as there may be other situations where redirectionids will be required
  handleClearRedirectId: () => void;
  removeGlobalDataFromStorageAsync: () => void;
};

export type TenanacyData = {
  tenancyId: number;
  propertyName: string;
  tenantProfileEntityId?: string;
  status: OccupancyStatusCodes;
};

export const GlobalTenantContext = createContext<GlobalContextType>({} as GlobalContextType);

// TODO: this context is need to be renamed to GlobalProvider, or be moved into Redux, as this context stores global data from owners and tenants now.
export const GlobalTenantProvider = ({ children }: any) => {
  const [tenancyId, setTenancyId] = useState<number>();
  const [tenancyData, setTenancyData] = useState<TenanacyData[]>([] as TenanacyData[]);
  const [redirectId, setRedirectId] = useState<number>(); // a way to store a redirect id, without passing it into the url
  useEffect(() => {
    loadDataFromStorage();
  }, []);

  const loadDataFromStorage = async () => {
    const tenancyIdJson = await getFromStorageAsync(TENANCY_ID_KEY);
    if (tenancyIdJson) {
      const _tenancyId = JSON.parse(tenancyIdJson);
      setTenancyId(_tenancyId);
    } else {
      setTenancyId(0);
    }

    const userTenanciesJson = await getFromStorageAsync(USER_TENANCIES);
    if (userTenanciesJson) {
      const _userTenancyData = JSON.parse(userTenanciesJson);
      setTenancyData(_userTenancyData);
    }
  };

  useEffect(() => {
    if (tenancyId) {
      setToStorageAsync(TENANCY_ID_KEY, tenancyId);
    }
    if (tenancyData && tenancyData.length > 0) {
      setToStorageAsync(USER_TENANCIES, tenancyData);
    }
  }, [tenancyId, tenancyData]);

  const hasMultipleTenancies = useMemo(() => {
    return tenancyData.length > 0;
  }, [tenancyData]);

  const getSelectedTenancy = useMemo(() => {
    if (tenancyId && tenancyData) return tenancyData.find((t) => t.tenancyId == tenancyId);
  }, [tenancyId, tenancyData]);

  const handleSetTenancyId = (id: number) => {
    if (id > 0 && id != undefined) setTenancyId(id);
  };
  const handleSetTenancyData = (tenancyData: TenanacyData[]) => {
    if (tenancyData) {
      setTenancyData(tenancyData);
    }
  };
  const handleSetRedirectId = (id: number) => {
    setRedirectId(id);
  };
  const handleClearRedirectId = () => setRedirectId(undefined);

  const setToStorageAsync = async (key: string, value: any) => {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  };

  const getFromStorageAsync = async (key: string) => {
    return await AsyncStorage.getItem(key);
  };
  const removeFromStorageAsync = async (key: string) => {
    return await AsyncStorage.removeItem(key);
  };
  const removeGlobalDataFromStorageAsync = async () => {
    await AsyncStorage.removeItem(TENANCY_ID_KEY);
    await AsyncStorage.removeItem(BRANDING_DATA_KEY);
    await AsyncStorage.removeItem(USER_TENANCIES);
    await AsyncStorage.removeItem(NAV_HISTORY_KEY);
    await AsyncStorage.removeItem(DEEP_LINK);
    await AsyncStorage.removeItem(IS_FIRST_TIME_LOGIN);
    await AsyncStorage.removeItem(HIDE_OFFER_BANNER);
    await AsyncStorage.removeItem(BANNER_OFFER_DURATION);
  };

  return (
    <GlobalTenantContext.Provider
      value={{
        tenancyId,
        hasMultipleTenancies,
        getSelectedTenancy,
        handleSetTenancyId,
        handleSetTenancyData,
        removeGlobalDataFromStorageAsync,
        redirectId,
        handleClearRedirectId,
        handleSetRedirectId
      }}
    >
      {children}
    </GlobalTenantContext.Provider>
  );
};
export const useGlobalTenant = () => useContext(GlobalTenantContext);
